import '../App.css'
import logo from '../PB001b_ComedySeen_090723_logo_2.jpg'
import { Center, Image, Text, Container, Space, Grid, SimpleGrid, Divider, Card, List, Anchor } from '@mantine/core'
import Footer from '../components/Footer';
import Head from '../components/Head'
import profile from '../290591517_10109352142199085_172794176054360776_n.jpg';
import { IconGlobe, IconBuildingSkyscraper, IconFilePlus } from '@tabler/icons-react'
import CountUp from 'react-countup'





function LeaderboardPrizes24() {



    const links = [


        {
            "link": "/About",
            "label": "About"

        },
        {
            "link": "/Support",
            "label": "Support"

        },
        {
            "link": "/Pp",
            "label": "Privacy"

        },
        {
            "link": "/Tos",
            "label": "Terms of Use"

        },

    ]




    return (

        <body>

            <Head></Head>

            <Center>
                <Image src={logo} fit='contain' width={400}></Image>
            </Center>

            <Text ta='center' sx={{ fontSize: '3rem' }}>2024 Leaderboard Prizes</Text>

            <Text ta='center'>In order to move up the leaderboard start by submitting events, checking-in, and reporting dead mics!</Text>

            <Center>
                <Anchor href="/Promotion24">
                    Official Rules

                </Anchor>

            </Center>
            <Space h='lg' />
            <Space h='lg' />
            <Space h='lg' />



            <Text ta='center' sx={{ fontSize: '2rem' }}>Comedians</Text>


            <Divider my="md" size='md' />

            <Center>

                <Card sx={{ background: '#d9ebf2' }}>


                    <SimpleGrid cols={2} spacing='xl'>
                        <div>

                            <Card sx={{ background: '#d9ebf2' }}>

                                <Center>
                                    <IconGlobe

                                        strokeWidth={2}
                                        color={'#532d86'} />

                                    <Text ta='center' td='underline'>Global</Text>
                                </Center>
                                <Space h='lg' />


                                <List


                                    spacing='xs'
                                    center>

                                    <Text fs='italic' fw={500} ta='center'>Top Comic</Text>
                                    <Space h='md' />

                                    <List.Item>First Place: $500</List.Item>
                                    <List.Item>Second Place: $400</List.Item>
                                    <List.Item>Third Place: $300</List.Item>
                                    <List.Item>Fourth Place: $200 </List.Item>
                                    <List.Item>Fifth Place: $100</List.Item>



                                    <Space h='md' />


                                    <Text fs='italic' fw={500} ta='center'>Hardest Working Comic/Pobblebonk</Text>

                                    <List.Item>Winner: $100</List.Item>

                                    <Space h='md' />

                                    <Text fs='italic' fw={500} ta='center'>Top Host/ Top Mic</Text>

                                    <List.Item>First Place: $150</List.Item>
                                    <List.Item>Second Place: $100</List.Item>
                                    <List.Item>Third Place: $50</List.Item>
                                    <Space h='lg' />
                                    <Space h='lg' />

                                    <Text fs='italic' fw={500} ta='center'>Top Showcase Submitter</Text>

                                    <List.Item>First Place: $100 in Facebook or IG ads to use for your shows + $100 to be used on the Comedy Seen ticketing platform + 1 on-site ticketing event</List.Item>
                                    <List.Item>Second Place: $50 in Facebook or IG ads to use for your shows + $50 to be used on the Comedy Seen ticketing platform + 1 on-site ticketing event</List.Item>
                                    <List.Item>Third Place: $25 in Facebook or IG ads to use for your shows + $25 to be used on the Comedy Seen ticketing platform + 1 on-site ticketing event</List.Item>

                                    <Space h='lg' />
                                    <Space h='lg' />

                                    <Text fs='italic' fw={500} ta='center'>Top Festival</Text>

                                    <List.Item>Winner: $100</List.Item>

                                    <Space h='lg' />
                                    <Space h='lg' />



                                    <Text ta='center'>Presented by:</Text>
                                    <Center>
                                        <Image src={logo} maw={100}></Image>
                                    </Center>



                                </List>

                                <Space h='md' />






                            </Card>

                        </div>


                        <div>


                            <Card sx={{ background: "#d9ebf2" }}>

                                <Center>

                                    <IconBuildingSkyscraper
                                        strokeWidth={2}
                                        color={'#4dbf40'} />
                                    <Text ta='center' td='underline'>Brooklyn</Text>
                                </Center>
                                <Space h='lg' />
                                <Center>

                                    <List>
                                        <Text fs='italic' fw={500} ta='center'>Top Comic</Text>
                                        <List.Item>First Place: $150</List.Item>
                                        <List.Item>Second Place: $100</List.Item>
                                        <List.Item>Third Place: $50</List.Item>

                                        <Space h='lg' />
                                        <Space h='lg' />


                                        <Text ta='center'>Presented by:</Text>
                                        <Center>
                                            <Image src={logo} maw={100}></Image>
                                        </Center>

                                    </List>

                                </Center>

                                <br/>

                                <Center>

                                    <IconBuildingSkyscraper
                                        strokeWidth={2}
                                        color={'#4dbf40'} />
                                    <Text ta='center' td='underline'>Miami</Text>
                                </Center>
                                <Space h='lg' />
                                <Center>

                                    <List>
                                        <Text fs='italic' fw={500} ta='center'>Top Comic</Text>
                                        <List.Item>First Place: 3 sets filmed and edited + help filming and editing a sketch + headshot session</List.Item>
                                        <List.Item>Second Place: 2 sets filmed and edited</List.Item>
                                        <List.Item>Third Place: 1 set filmed and edited</List.Item>

                                        <Space h='lg' />
                                        <Space h='lg' />


                                        <Text ta='center'>Presented by:</Text>
                                        <Center>
                                            <Image src={logo} maw={100}></Image>
                                        </Center>

                                    </List>

                                </Center>

                            </Card>



                        </div>


                    </SimpleGrid>


                </Card>

            </Center>
            <Center>
                <Text>
                    Cash prizes given directly to comedians since 2022:
                </Text>
            </Center>

            <Center>
                <Text size='xl' fw={700}>
                $
                <CountUp 
                end={3100}
                duration={15}/>
                </Text>
            </Center>

            <br/>



            <Text ta='center' sx={{ fontSize: '2rem' }}>Fans</Text>

            <Divider my="md" size='md' />


            <Center>
                <Card sx={{ background: '#d9ebf2' }}>


                    <Center>
                        <IconGlobe
                            strokeWidth={2}
                            color={'#40bfbf'} />

                        <Text ta='center' td='underline'>Global</Text>

                    </Center>

                    <List>
                        <Text fs='italic' fw={500}>Top Fan</Text>
                        <List.Item>First Place: Pair of tickets to stand up show (max $100 face value/ticket)</List.Item>
                        <List.Item>Second Place: Pair of tickets to stand up show (max $50 face value/ticket)</List.Item>
                        <List.Item>Third Place: Pair of tickets to stand up show (max $25 face value/ticket)</List.Item>

                    </List>





                </Card>







            </Center>
            <Space h='lg' />
            <Space h='lg' />
            <Space h='lg' />
            <Space h='lg' />
            <Space h='lg' />





            <Footer links={links} />



        </body>




    )



}


export default LeaderboardPrizes24