import Head from '../components/Head'
import Footer from '../components/Footer'
import {
    Card, Text, Center, createStyles, rem, Title, Container,
    Space, List, NumberInput, Slider, Switch, SimpleGrid
} from '@mantine/core'
import { useState, useEffect } from 'react'

const useStyles = createStyles((theme) => ({
    header: {
        backgroundColor: "#d9ebf2"

    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: `${rem(8)} ${rem(12)}`,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],


        }
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'


    },

}));

const links = [
    {
        'link': '/About',
        'label': 'About'
    },
    {
        'link': '/Support',
        'label': 'Support'
    },
    {
        'link': '/Pp',
        'label': 'Privacy'
    },
    {
        'link': '/Tos',
        'label': 'Terms of Use'
    }

]



function Pricing() {

    const { classes, cx } = useStyles()
    const [inputPrice, setInputPrice] = useState<number | "">(10)
    const [inputSold, setInputSold] = useState<number>(100)
    const [costTotal, setCostTotal] = useState<number>(1000)
    const [stripe, setStripe] = useState<boolean>(false)
    const [eventbriteCost, setEventbriteCost] = useState<number>(216)
    const [ticketTailorCost, setTicketTailorCost] = useState<number>(28)
    const [ticketTailorCosthigh, setTicketTailorCosthigh] = useState<number>(75)
    const fee = 0

    interface NumberInputProps {
        label: string;
        defaultValue: number;
        parser: (value: number) => number;
        formatter: (value: number) => number
    }


    const getCost = () => {

        if (typeof (inputPrice) === 'number') {
            console.log('stripe', stripe)

            if (stripe === false) {

                setCostTotal(inputPrice * inputSold * fee)
                setEventbriteCost((inputPrice * inputSold * .037) + (1.79 * inputSold))
                setTicketTailorCost(Math.round(inputSold * .28))
                setTicketTailorCosthigh(Math.round(inputSold * .75))
            } else {
                setCostTotal((inputPrice * inputSold * fee) + (inputPrice * inputSold * .029) + .3)
                setEventbriteCost((inputPrice * inputSold * .037) + (1.79 * inputSold) + (inputSold * .029))
                setTicketTailorCost((inputSold * .28) + (inputPrice * inputSold * .029) + .3)
                setTicketTailorCosthigh((inputSold*.75) + (inputPrice * inputSold * .029) + .3)

            }

        } else {
            console.log('no change to inputs')
        }
    }

    useEffect(() => {

        getCost()

    }, [inputPrice, inputSold, stripe])




    return (
        <body>
            <Head></Head>

            <Center>
                <Text sx={{ fontSize: '5rem' }}>Low cost.  No joke.</Text>
            </Center>

            <Space h='xl' />

            <Center>
                <Card
                    padding='xl'
                    radius='xl'
                    withBorder
                    shadow='sm'
                    sx={{ width: '50rem' }}>


                    <Center>

                        <Text sx={{ fontSize: '2rem' }}>Lock in FREE.</Text>


                    </Center>



                    <Center>
                        <List>
                            <List.Item>Prices will eventually change to accomodate future usage, but even when they do, you always lock in the price
                                that was current when you sell your first ticket.</List.Item>
                            <List.Item>Join the pilot program to ensure that you will lock in free pricing! In order to qualify, you need to have earned 
                                at least one point in the app before Dec. 15, 2024, then just ask us on socials to join!  Pilot program users must use the
                                ticketing service in the first half of 2025.  Users will have the opportunity to lock in free pricing afterwards, but it could
                                possibly be a short opportunity.
                            </List.Item>
                            <List.Item>Simply one price per ticket</List.Item>
                            <List.Item>Price will always be ultra competitive</List.Item>
                            <List.Item>Comedy fans can discover your event on the platform</List.Item>
                            <List.Item>Service launches in 2025!</List.Item>
                        </List>

                    </Center>

                </Card>
            </Center>
            <Space h='xl' />

            <Center>
                <Card
                    padding='xl'
                    radius='xl'
                    withBorder
                    shadow='sm'
                    sx={{ width: '50rem' }}>

                    <Center>
                        <Text sx={{ fontSize: '2rem' }}>Fee Calculator</Text>
                    </Center>
                    <Center>
                        <Text>Include typical processing fees?</Text>

                    </Center>
                    <Space h='sm' />


                    <Center>

                        <Switch
                            checked={stripe}
                            onChange={(event) => setStripe(event.currentTarget.checked)}

                        />

                    </Center>
                    <Space h='sm' />

                    <Center>
                        <Text sx={{ fontSize: '.75rem' }}>
                            (we will partner with Stripe)
                        </Text>
                    </Center>


                    <br />




                    <Text>Ticket price in $</Text>
                    <br />
                    <NumberInput
                        value={inputPrice}
                        onChange={setInputPrice}>
                    </NumberInput>
                    <br />

                    <Text>
                        Tickets Sold
                    </Text>
                    <br />

                    <Slider
                        min={0}
                        max={500}
                        step={50}
                        value={inputSold}
                        onChange={setInputSold}
                        marks={[
                            { value: 50, label: '50' },
                            { value: 100, label: '100' },
                            { value: 150, label: '150' },
                            { value: 200, label: '200' },
                            { value: 250, label: '250' },
                            { value: 300, label: '300' },
                            { value: 350, label: '350' },
                            { value: 400, label: '400' },
                            { value: 450, label: '450' },
                            { value: 500, label: '500' }
                        ]} />
                    <br />
                    <br />

                    <Text>
                        This is how much you will pay
                    </Text>


                    <Text sx={{ textAlign: 'right', fontSize: '1.5rem' }}>${costTotal.toFixed(2)}</Text>
                    <br />









                </Card>

            </Center>
            <Space h='xl' />

            <Center>
                <Card
                    padding='xl'
                    radius='xl'
                    withBorder
                    shadow='sm'
                    sx={{ width: '50rem' }}>

                    <Center>
                        <Text sx={{ fontSize: '2rem' }}>Or pay more with others</Text>
                    </Center>

                    <SimpleGrid cols={2}>
                        <div>Eventbrite </div>
                        <div>
                            <Text sx={{ textAlign: 'right' }}>${eventbriteCost.toFixed(2)}</Text>
                        </div>
                        <div>Ticket Tailor* (Pay as you sell)</div>
                        <div>
                            <Text sx={{ textAlign: 'right'}}>${ticketTailorCosthigh.toFixed(2)}</Text>
                        </div>
                        <div>Ticket Tailor* (Pay upfront)</div>
                        <div>
                            <Text sx={{ textAlign: 'right' }}>${ticketTailorCost.toFixed(2)}</Text>
                        </div>

                    </SimpleGrid>
<br/>
                    <Center>
                        <Text sx={{ width: '50rem' }}>
                            *Keep in mind that Ticket Tailor does not provide discovery like Comedy Seen or EventBrite.
                        </Text>
                    </Center>


                </Card>
            </Center>
            <Space h='xl' />


            <br />
            <br />
            <br />
            <br />


            <Footer links={links} />

        </body>


    )
}


export default Pricing