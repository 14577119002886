import {
    Anchor, Center, Text, Grid, Group, Button,
    Container, Box, Header, createStyles, rem
} from '@mantine/core'
import { Link } from 'react-router-dom'
import { IconStar } from '@tabler/icons-react'
import '../App.css'








export default function Head() {

    const useStyles = createStyles((theme) => ({
        header: {
            backgroundColor: "#d9ebf2"
    
        },
    
        link: {
            display: 'block',
            lineHeight: 1,
            padding: `${rem(8)} ${rem(12)}`,
            borderRadius: theme.radius.sm,
            textDecoration: 'none',
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
            fontSize: theme.fontSizes.sm,
            fontWeight: 500,
    
            '&:hover': {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    
    
            }
        },
    
        inner: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
    
    
        }
    }));

    const { classes, cx } = useStyles()

    const headerlinks = [
        {
            'link': '/Blog',
            'label': 'Blog',
            'index': 'one'

        },
        {
            'link': '/HOF',
            'label': 'Hall of Fame',
            'index': 'two'

        },
        {
            'link': '/LeaderboardPrizes24',
            'label': 'Leaderboard Prizes',
            'index': 'three'
        },
        {
            'link': '/Pricing',
            'label': 'Ticket Pricing',
            'index': 'four'
        }

    ]

    const headeritems = headerlinks.map((link) => (

        <Anchor
            color='dimmed'
            key={link.label}
            href={link.link}
            size='md'
        >
            <Link to={link.link}>
                {link.index === 'two' ?
                    <div>
                        <Center>
                            <IconStar
                                size={15}
                                color='orange'
                            />
                            <Text className="HOF-header">
                                {link.label}
                            </Text>
                            <IconStar
                                size={15}
                                color='orange' />
                        </Center>
                    </div>
                    :
                    <div>
                        {link.label}
                    </div>
                }

            </Link>

        </Anchor>

    )
    )


    return (

        

            <Box pb={120} pt={40}>



                    <Container className={classes.inner} fluid>

                        <Group></Group>
                        <Grid justify='center'>
                            <Group spacing='xl'>
                                {headeritems}
                            </Group>
                        </Grid>

                        <Grid justify='flex-end'>
                            <Button
                                color='green'
                                size='md'
                                component='a'
                                href='https://app.getcomedyseen.com/Login'
                                type='button'
                            >Log in/Sign up
                            </Button>
                        </Grid>
                    </Container>

            </Box>
        


    )


}