
import {
    Box, Header, Grid, Container, createStyles, rem, Group, Anchor,
    Center, Text, Image, Title, Space, SimpleGrid, Tabs, Card, Button
} from '@mantine/core'
import '../App.css'
import { Link } from 'react-router-dom'
import { IconStar } from '@tabler/icons-react'
import transparentlogo from '../cs_frogtop_transparent_cropped.png'
import twophone from '../Mobile_smartphone_two_dark.png'
import laptop from '../laptop_final.png'
import apple from '../Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg';
import android from '../google-play-badge.png';
import Footer from '../components/Footer'
import Head from '../components/Head'

const useStyles = createStyles((theme) => ({
    header: {
        backgroundColor: "#d9ebf2"

    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: `${rem(8)} ${rem(12)}`,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],


        }
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'


    },


}));


function Landing() {

    const { classes, cx } = useStyles()



    const headerlinks = [
        {
            'link': '/Blog',
            'label': 'Blog',
            'index': 'one'

        },
        {
            'link': '/HOF',
            'label': 'Hall of Fame',
            'index': 'two'

        },
        {
            'link': '/LeaderboardPrizes24',
            'label': 'Leaderboard Prizes',
            'index': 'three'
        },
        {
            'link': '/Pricing',
            'label': 'Pricing',
            'index': 'four'
        }

    ]

    const links = [
        {
            'link': '/About',
            'label': 'About'
        },
        {
            'link': '/Support',
            'label': 'Support'
        },
        {
            'link': '/Pp',
            'label': 'Privacy'
        },
        {
            'link': '/Tos',
            'label': 'Terms of Use'
        }

    ]

    const fan_cards = [
        
        { title: 'Discover and decide the next top comics at the ground level', content: 'Every year we reward the top comics on the app both globally and locally.  Nothing affects these rankings more than after show favoriting from fans and supporters!' },
        { title: 'Find live stand up that matches your personal sense of humor', content: 'Everyone has a different sense of humor.  Build your sense of humor profile by ranking your favorite comedians and see how you match up with comedians/friends/shows around you' },
        { title: 'Win prizes', content: 'Just like we have Top Comic awards we have Top Fan awards as well for you to win!'},
        { title: 'Collect badges as you explore the live stand up scene', content: "Pick up badges by checking in at open mics, festivals, and showcases.  You'll prove that you're a real live stand up comedy enthusiast!  You don't just 'like' videos on instagram, you show up and have a good time!" },
        { title: "Keep track of comedians you've seen", content: "Ever go to a show, have a great time but not even remember the name of your favorite comic that night?  In Comedy Seen, you keep track of all the comics you've seen automatically."},
        { title: 'Buy tickets to local stand up shows without paying exorbitant fees', content: "coming in 2025!"}
    ]

    const comedian_cards = [
        { title: 'No mysterious algorithm.  Get exposure on the app by just doing stand up comedy', content: "No need to become a videographer to make reels or becoming a social media master.  Just get exposure by being a good comedian." },
        { title: 'Attract stand up comedy fans who have similar senses of humor as you', content: 'Comedy Seen facilitates fans who want to find stand up comedy shows featuring comics who have the same sense of humor as them' },
        { title: 'Get rewarded with $$$', content: "At the end of the year if you place globally or in locally sponsored leaderboards for up and coming comics, you'll get cold hard cash!  No fee to participate!" },
        { title: 'Collect badges throughout your comedy journey', content: 'Badges are a fun thing to pick up at shows and a cool way to commemorate your career' },
        { title: 'Crowd sourced open mic map', content: 'Find open mics on our crowd sourced open mic map.  Contribute to the map and keep it current to earn points that move you up the leaderboard.'}
    ]

    const producer_cards = [
        { title: 'Fill rooms with more laughter', content: 'Fans who find your shows on Comedy Seen are more likely to enjoy your show because their senses of humor match' },
        { title: 'Lowest fees in the business', content: 'In 2025, We will have a no frills and cheap ticketing platform that is priced to be free or as close to free as we can manage.  Whatever price you lock in will be yours forever.' },
        { title: 'Get discovered by hardcore stand up comedy fans', content: 'Comedy Seen is for the fans of LIVE stand up comedy who actually go to shows and not just like your posts'},
        { title: 'Draw fans to your shows with badges', content: 'Whether you run a open mic, showcase, or a festival you can have badges that fans can pick up so they can prove their elite fan status' },


    ]


    const headeritems = headerlinks.map((link) => (

        <Anchor
            color='dimmed'
            key={link.label}
            href={link.link}
            size='md'
        >
            <Link to={link.link}>
                {link.index === 'two' ?
                    <div>
                        <Center>
                            <IconStar
                                size={15}
                                color='orange'
                            />
                            <Text className="HOF-header">
                                {link.label}
                            </Text>
                            <IconStar
                                size={15}
                                color='orange' />
                        </Center>
                    </div>
                    :
                    <div>
                        {link.label}
                    </div>
                }

            </Link>

        </Anchor>

    )


    )




    return (

        <body>

           <Head></Head>






            <Box p={25}>
                <Grid gutter={4} gutterMd="xl" grow>
                    <Grid.Col span={5} style={{ minHeight: rem(80) }}>
                        <Image src={twophone} fit='contain'></Image>
                    </Grid.Col>

                    <Grid.Col span={2} offset={1}>

                        <Center>
                            <Image src={transparentlogo} fit='contain' maw={200} mx='auto'></Image>
                        </Center>


                        <Space h='lg' />
                        <Space h='lg' />
                        <Space h='lg' />
                        <Space h='lg' />
                        <Space h='lg' />
                        <Space h='lg' />

                        <Container>
                            <Title ta="center" color='#a300e0' sx={{ headings: {fontFamily: 'Helvetica, sans-serif'}, fontSize: '5rem' }} order={1}>Find <span style={{ color: 'green' }}>YOUR</span> Funny</Title>
                        </Container>

                        <Space h='lg' />
                        <Space h='lg' />


                        <Container>

                            <SimpleGrid cols={2}>
                                <Anchor href="https://apps.apple.com/us/app/pobblebonk-app/id1606669167">
                                    <Image src={apple} maw={400} mx='auto' fit='cover' />
                                </Anchor>
                                <Anchor href="https://play.google.com/store/apps/details?id=com.pobblebonk.pobblebonk">
                                    <Image src={android} maw={450} mx='auto' fit='cover' />
                                </Anchor>

                            </SimpleGrid>

                        </Container>



                    </Grid.Col>

                </Grid>
            </Box>

            <Space h='lg' />
            <Space h='lg' />


            <Box p={50}>
                <Tabs defaultValue="Fans" color="green" variant='pills'>
                    <Tabs.List position='center'>
                        <Tabs.Tab sx={{ fontSize: '1.5rem' }} value="Fans">Fans</Tabs.Tab>
                        <Tabs.Tab sx={{ fontSize: '1.5rem' }} value="Comedians">Comedians</Tabs.Tab>
                        <Tabs.Tab sx={{ fontSize: '1.5rem' }} value="Producers">Producers</Tabs.Tab>
                    </Tabs.List>


                    <Tabs.Panel value="Fans" pt="xs">


                        <SimpleGrid
                            cols={2}
                            spacing="xl"
                            color='red'
                            breakpoints={[
                                { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                                { maxWidth: '36rem', cols: 1, spacing: 'sm' }

                            ]}>

                            {fan_cards.map((card, index) => (



                                <div color='red'>
                                    <Card sx={{ background: "#d9ebf2" }} shadow="sm" padding="lg" radius='md' withBorder>
                                        <Text fz="xl" fw={700}>{card.title}</Text>
                                        <Text>{card.content}</Text>

                                    </Card>
                                </div>

                            )


                            )}

                        </SimpleGrid>



                    </Tabs.Panel>
                    <Tabs.Panel value="Comedians">


                        <SimpleGrid
                            cols={2}
                            spacing="xl"
                            breakpoints={[
                                { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                                { maxWidth: '36rem', cols: 1, spacing: 'sm' }

                            ]}>

                            {comedian_cards.map((card, index) => (



                                <div>
                                    <Card sx={{ background: "#d9ebf2" }} shadow="sm" padding="lg" radius='md' withBorder>
                                        <Text fz="xl" fw={700}>{card.title}</Text>
                                        <Text>{card.content}</Text>

                                    </Card>
                                </div>

                            )


                            )}

                        </SimpleGrid>


                    </Tabs.Panel>


                    <Tabs.Panel value="Producers">


                        <SimpleGrid
                            cols={2}
                            spacing="xl"
                            breakpoints={[
                                { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                                { maxWidth: '36rem', cols: 1, spacing: 'sm' }

                            ]}>

                            {producer_cards.map((card, index) => (



                                <div>
                                    <Card sx={{ background: "#d9ebf2" }} shadow="sm" padding="lg" radius='md' withBorder>
                                        <Text fz="xl" fw={700}>{card.title}</Text>
                                        <Text>{card.content}</Text>

                                    </Card>
                                </div>

                            )


                            )}

                        </SimpleGrid>


                    </Tabs.Panel>

                </Tabs>

            </Box>


            <Footer links={links} />


        </body>



    )



}


export default Landing